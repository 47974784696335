/*eslint-disable */
// import eventLevelModel from '../../Model/eventLevel'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"

let eventLevels = {
  /**
   * eventLevelList
   */
  async eventLevelList (context) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "eventLevel_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at eventLevelList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * eventLevelView
   */
  async eventLevelView (context, eventLevelId) {
    try {
      
      let post_data = new FormData();
      post_data.append('event_lev_id', eventLevelId);
      return await request.curl(context, "eventLevel_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at eventLevelView() and Exception:",err.message)
    }
    
  },

  /**
   * eventLevelAdd
   */
  async eventLevelAdd (context, eventLevelObj) {
    try{
    let post_data = new FormData();

    for (let key in eventLevelObj) {
      post_data.append(key, eventLevelObj[key]);
    }

    return await request.curl(context, "eventLevel_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at eventLevelAdd() and Exception:',err.message)
    }
  },

  /**
   * eventLevelEdit
   */
  async eventLevelEdit (context, eventLevelObj) {
    try{
    let post_data = new FormData();

    for (let key in eventLevelObj) {
      post_data.append(key, eventLevelObj[key]);
    }

    return await request.curl(context, "eventLevel_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at eventLevelEdit() and Exception:',err.message)
    }
  },

  /**
   * eventLevelDelete
   */
  async eventLevelDelete (context, eventLevelId) {
    try{
    let post_data = new FormData();
    
    post_data.append('event_lev_id', eventLevelId);

    return await request.curl(context, "eventLevel_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at eventLevelDelete() and Exception:',err.message)
    }
  }
}

export {
  eventLevels
}
