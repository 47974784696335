/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
          <template>
            <p v-if="!propOpenedInModal">
              {{ cvCardSubHeader }}
            </p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationeventLevelName">
                    {{cvProfBulActLevelNameLabel}}</label>
                  <input
                    v-model="vmeventLevelFormData.event_lev_name"
                    type="text"
                    class="form-control"
                    required/>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus"
                        type="button"
                        class="btn btn-primary"
                        :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning">
                  </b-spinner>
                </button>
                <button v-else
                        type="button"
                        class="btn btn-primary"
                        @click="eventLevelAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { eventLevels } from "../../../FackApi/api/eventLevel"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "eventLevelAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add Event Level",
      cvCardSubHeader: "Add Event Level ",
      cvSubmitBtn: "Add",
      cvProfBulActLevelNameLabel: "Activity Level Name",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Event Level",
      vmeventLevelFormData: Object.assign({}, this.initFormData()),
      vmeventLevelDesc: null,
      vmeventLevelType: null,
      cvLoadingStatus: false
    }
  },
  mounted () {
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "event_lev_name": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmeventLevelFormData) {
          if (!this.vmeventLevelFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * eventLevelAdd
     */
    async eventLevelAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let eventLevelAddResp = await eventLevels.eventLevelAdd(this, this.vmeventLevelFormData)
        await ApiResponse.responseMessageDisplay(this, eventLevelAddResp)
        if (eventLevelAddResp && !eventLevelAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseeventLevelAddModal", eventLevelAddResp.resp_data)
        }
        this.vmeventLevelFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at eventLevelAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
